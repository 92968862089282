import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Spinner } from "@chakra-ui/react";
import Hero from "./landingPage/Hero.tsx";
import Nav from "./dash/Nav.tsx";
import Dashboard from "./dash/Dashboard.tsx";
import Profile from "./dash/Profile.tsx";
import Assistant from "./dash/Assistant.tsx";
import MinvestEd from "./dash/MinvestEd.tsx";
import Simvest from "./dash/Simvest.tsx";
import View from "./dash/View.tsx";
import Guest from "./dash/Guest.tsx";

export default function App() {
    const { isLoading, isAuthenticated } = useAuth0();

    console.log(isLoading);
    console.log(isAuthenticated);

    if (isLoading) {
        return (
            <div className="App">
                <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="purple.500"
                    size="xl"
                    position="absolute"
                    top="50%"
                    left="50%"
                />
            </div>
        );
    }


    if (!isAuthenticated) {
        return (
            <Router>
            <div className="App">
                <Routes>
                    <Route path="/guest" element={<Guest />} />
                    <Route path="/" element={<Hero />} />
                </Routes>
            </div>
            </Router>
        )
    }

    if (isAuthenticated) {
        return (
            <Router>
                <div className="App">
                    <Nav>
                        <Routes>
                            <Route path="/" element={<Dashboard />} />
                            <Route path="/profile" element={<Profile />} />
                            <Route path="/view" element={<View />} />
                            <Route path="/assistant" element={<Assistant />} />
                            <Route path = "/minvestEd" element={<MinvestEd />} />
                            <Route path = "/Simvest" element={<Simvest />} />
                        </Routes>
                    </Nav>
                </div>
            </Router>
        );
    }

    return (
        <Router>
            <div className="App">

                <Routes>
                    {/* Public route, no authentication needed */}
                    <Route path="/guest" element={<Guest />} />

                    {/* Private routes, require authentication */}
                    {isAuthenticated && (
                        <Nav>
                            <Route>
                                <Route path="/" element={<Dashboard />} />
                                <Route path="/profile" element={<Profile />} />
                                <Route path="/view" element={<View />} />
                                <Route path="/assistant" element={<Assistant />} />
                                <Route path="/minvestEd" element={<MinvestEd />} />
                                <Route path="/Simvest" element={<Simvest />} />
                            </Route>
                        </Nav>

                    )}

                    {/* Default route when not authenticated */}
                    {!isAuthenticated && <Route path="/" element={<Hero />} />}
                </Routes>

            </div>
        </Router>
    );

}
